const testimonies = [
    {
        testimony: `“ This is an super space for your customers qoute.
            Don’t worry it works smooth as pie. You will get all what you need by writiing a text here “`,
        nameSurname: `Name and Surname`
    }, {
        testimony: `“ This is an super space for your customers qoute.
            Don’t worry it works smooth as pie. You will get all what you need by writiing a text here “`,
        nameSurname: `Name and Surname`
    }, {
        testimony: `“ This is an super space for your customers qoute.
            Don’t worry it works smooth as pie. You will get all what you need by writiing a text here “`,
        nameSurname: `Name and Surname`
    }, {
        testimony: `“ This is an super space for your customers qoute.
            Don’t worry it works smooth as pie. You will get all what you need by writiing a text here “`,
        nameSurname: `Name and Surname`
    }, {
        testimony: `“ This is an super space for your customers qoute.
            Don’t worry it works smooth as pie. You will get all what you need by writiing a text here “`,
        nameSurname: `Name and Surname`
    }, {
        testimony: `“ This is an super space for your customers qoute.
            Don’t worry it works smooth as pie. You will get all what you need by writiing a text here “`,
        nameSurname: `Name and Surname`
    }, {
        testimony: `“ This is an super space for your customers qoute.
            Don’t worry it works smooth as pie. You will get all what you need by writiing a text here “`,
        nameSurname: `Name and Surname`
    }, {
        testimony: `“ This is an super space for your customers qoute.
            Don’t worry it works smooth as pie. You will get all what you need by writiing a text here “`,
        nameSurname: `Name and Surname`
    }, {
        testimony: `“ This is an super space for your customers qoute.
            Don’t worry it works smooth as pie. You will get all what you need by writiing a text here “`,
        nameSurname: `Name and Surname`
    }, {
        testimony: `“ This is an super space for your customers qoute.
            Don’t worry it works smooth as pie. You will get all what you need by writiing a text here “`,
        nameSurname: `Name and Surname`
    }]
export default testimonies;